/**
 *时间格式化
 * @param {Date} date
 * @param {String} _fmt
 */
exports.dateFormat = function (date, _fmt) {
	if (!date) {
		return '';
	}
	var thisDate = date;
	if (typeof date === 'string') {
		return date;
	}
	if (typeof date === 'number') {
		thisDate = new Date(date);
	}
	var o = {
		'M+': thisDate.getMonth() + 1, // 月份
		'D+': thisDate.getDate(), // 日
		'H+': thisDate.getHours(), // 小时
		'm+': thisDate.getMinutes(), // 分
		's+': thisDate.getSeconds(), // 秒
		'q+': Math.floor((thisDate.getMonth() + 3) / 3), // 季度
		S: thisDate.getMilliseconds() // 毫秒
	};
	var fmt = _fmt || 'YYYY-MM-DD';
	if (/(Y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, String(thisDate.getFullYear()).substr(4 - RegExp.$1.length));
	for (var k in o) {
		if (new RegExp('(' + k + ')').test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(String(o[k]).length)));
	}
	return fmt;
};

/**
 * 倒计时
 * @param {Number} time 剩余的时间
 */
exports.getTimeInfo = function (time) {
	if (!time || typeof time !== 'number') {
		return '时间格式错误！';
	}
	const d = Math.floor(time / (24 * 3600 * 1000));
	time = time - d * 86400000;
	const h = Math.floor(time / (3600000));
	time = time - h * 3600000;
	const m = Math.floor(time / 60000);
	time = time - m * 60000;
	const s = Math.floor(time / 1000);

	return { d, h, m, s };
};