export default {
	dept_type: {
		11000: '内科',
		12000: '外科',
		13000: '妇产科',
		14000: '生殖中心',
		15000: '儿科',
		16000: '骨科',
		17000: '眼科',
		18000: '口腔科',
		19000: '耳鼻咽喉头颈科',
		20000: '肿瘤科',
		21000: '精神心理科',
		22000: '中医学',
		23000: '中西医结合科',
		24000: '结核病科',
		25000: '介入医学科',
		26000: '康复医学科',
		27000: '运动医学科',
		28000: '麻醉医学科',
		29000: '营养科',
		30000: '医学影像学',
		31000: '病理科',
		32000: '传染病科'
	},
	dept_list: {
		11001: '心血管内科',
		11002: '神经内科',
		11003: '消化内科',
		11004: '内分泌科',
		11005: '免疫科',
		11006: '呼吸科',
		11007: '肾病内科',
		11008: '血液科',
		11009: '感染内科',
		11010: '过敏反应科',
		11011: '老年病科',
		11012: '普通内科',
		11013: '高压氧科',
		12001: '神经外科',
		12002: '心血管外科',
		12003: '胸外科',
		12004: '整形科',
		12005: '乳腺外科',
		12006: '泌尿外科',
		12007: '肝胆外科',
		12008: '肛肠科',
		12009: '血管外科',
		12010: '器官移植',
		12011: '微创外科',
		12012: '普外科',
		12013: '胃肠外科',
		12014: '功能神经外科',
		13001: '妇科',
		13002: '产科',
		13003: '妇科内分泌',
		13004: '妇泌尿科',
		13005: '产前诊断科',
		13006: '遗传咨询科',
		13007: '计划生育科',
		13008: '妇产科',
		14001: '生殖中心',
		15001: '儿科',
		15002: '新生儿科',
		15003: '小儿呼吸科',
		15004: '小儿消化科',
		15005: '儿童保健科',
		15006: '小儿神经内科',
		15007: '小儿心内科',
		15008: '小儿肾内科',
		15009: '小儿内分泌科',
		15010: '小儿免疫科',
		15011: '小儿皮肤科',
		15012: '小儿耳鼻喉',
		15013: '小儿血液科',
		15014: '小儿感染科',
		15015: '小儿精神科',
		15016: '小儿妇科',
		15017: '小儿外科',
		15018: '小儿心外科',
		15019: '小儿胸外科',
		15020: '小儿骨科',
		15021: '小儿泌尿科',
		15022: '小儿神经外科',
		15023: '小儿整形科',
		15024: '小儿康复科',
		15025: '小儿急诊科',
		15026: '新生儿外科',
		16001: '骨科',
		16002: '脊柱外科',
		16003: '手外科',
		16004: '创伤骨科',
		16005: '骨关节科',
		16006: '矫形骨科',
		16007: '骨质疏松科',
		16008: '足踝外科',
		17001: '眼科',
		17002: '小儿眼科',
		17003: '眼底',
		17004: '角膜科',
		17005: '青光眼',
		17006: '白内障',
		17007: '眼外伤',
		17008: '眼眶及肿瘤',
		17009: '屈光',
		17010: '眼整形',
		17011: '中医眼科',
		17012: '视光科',
		18001: '口腔科',
		18002: '颌面外科',
		18003: '正畸科',
		18004: '牙体牙髓科',
		18005: '牙周科',
		18006: '口腔粘膜科',
		18007: '儿童口腔科',
		18008: '口腔修复科',
		18009: '种植科',
		19001: '耳鼻喉',
		19002: '头颈外科',
		20001: '肿瘤内科',
		20002: '肿瘤外科',
		20003: '肿瘤妇科',
		20004: '放疗科',
		20005: '骨肿瘤科',
		20006: '肿瘤康复科',
		20007: '肿瘤综合科',
		21001: '精神科',
		21002: '心理咨询科',
		22001: '中医妇产科',
		22002: '中医儿科',
		22003: '中医骨科',
		22004: '中医皮肤科',
		22005: '中医内分泌',
		22006: '中医消化科',
		22007: '中医呼吸科',
		22008: '中医肾病内科',
		22009: '中医免疫内科',
		22010: '中医心内科',
		22011: '中医神经内科',
		22012: '中医肿瘤科',
		22013: '中医血液科',
		22014: '中医感染内科',
		22015: '中医肝病科',
		22016: '中医五官科',
		22017: '中医男科',
		22018: '针灸科',
		22019: '中医按摩科',
		22020: '中医外科',
		22021: '中医乳腺外科',
		22022: '中医肛肠科',
		22023: '中医老年病科',
		22024: '中医科',
		23001: '中西医结合科',
		32001: '肝病科',
		32002: '传染科',
		32003: '艾滋病科',
		32004: '传染危重室',
		24001: '结核病科',
		25001: '介入科',
		26001: '康复科',
		26002: '理疗科',
		27001: '运动医学科',
		28001: '疼痛科',
		28002: '麻醉科',
		29001: '营养科',
		30001: '核医学科',
		30002: '放射科',
		30003: '超声科',
		30004: '医学影像科',
		31001: '病理科'
	}
};